import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import DownloadPlatformView from '@views/DownloadPlatformView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const DownloadPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  return (
    <DefaultTemplate>
      <PageMeta description={t('pages:downloadDescription')} title={t('pages:download')} />
      <DownloadPlatformView />
    </DefaultTemplate>
  );
};

export default DownloadPage;
