import CurrentPomelloVersion from '@components/CurrentPomelloVersion';
import useTranslation from '@helpers/useTranslation';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import { Button, Columns, Container, Content, Heading, Section } from 'react-bulma-components';
import { DOWNLOAD_PAGE } from '../../constants';

const DownloadPlatformView: FC = () => {
  const { t } = useTranslation('download');

  const downloadButtons = {
    mac: 'Mac OS',
    windows: 'Windows',
    linux: 'Linux',
  };

  return (
    <Section>
      <Container textAlign="center">
        <Content>
          <Heading>{t('downloadPomello')}</Heading>
          <p>{t('downloadDescription')}</p>
          <p>{t('availablePlatforms')}</p>
          <Columns centered m={6}>
            {Object.entries(downloadButtons).map(([platform, label]) => (
              <Columns.Column display="flex" flexDirection="column" key={platform} narrow>
                <Button
                  color="primary"
                  outlined
                  renderAs={Link}
                  size="medium"
                  to={`${DOWNLOAD_PAGE}/${platform}`}
                >
                  {label}
                </Button>
              </Columns.Column>
            ))}
          </Columns>
          <CurrentPomelloVersion />
        </Content>
      </Container>
    </Section>
  );
};

export default DownloadPlatformView;
